var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$vuetify.breakpoint.xsOnly)?_c('v-select',{staticClass:"my-4",staticStyle:{"height":"20px"},attrs:{"items":_vm.tabs,"item-text":"text","item-value":"href","outlined":"","dense":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}):_vm._e(),(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-tabs',{staticClass:"pl-0",staticStyle:{"border-color":"white","border-bottom":"-2px","border-width":"1px"},attrs:{"right":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"pl-0 ml-0 pr-0",staticStyle:{"font-size":"14px"},attrs:{"href":"#general","active-class":"color"}},[_vm._v(_vm._s(_vm.$t("inventorys.inventory")))]),_c('v-tab',{staticStyle:{"font-size":"14px"},attrs:{"href":"#entradas","active-class":"color"},on:{"click":function($event){_vm.type = null}}},[_vm._v(_vm._s(_vm.$t("inventorys.receipts_table")))]),_c('v-tab',{staticStyle:{"font-size":"14px"},attrs:{"href":"#salidas","active-class":"color"},on:{"click":function($event){_vm.type = null}}},[_vm._v(_vm._s(_vm.$t("inventorys.issues_table")))]),_c('v-row',{attrs:{"justify":"end"}},[(_vm.tab !== 'general')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-select',{staticClass:"mb-1",attrs:{"outlined":"","dense":"","label":_vm.$t(
                _vm.tab == 'entradas'
                  ? 'inventorys.type_product'
                  : 'inventorys.type_use'
              ),"hide-details":"","items":_vm.types,"clearable":""},on:{"change":_vm.reload},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1):_vm._e(),(_vm.count > 0)?_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{attrs:{"color":"red","outlined":_vm.filters.min_stock !== true,"elevation":"0","height":"30px","block":""},on:{"click":function($event){_vm.filters.min_stock = true;
              _vm.reload();}}},[_vm._v(" "+_vm._s(_vm.$t("inventorys.low_stock", { count: _vm.count }))+" "),(_vm.filters.min_stock)?_c('v-btn',{staticClass:"internal",attrs:{"icon":"","color":"black"},on:{"click":function($event){$event.stopPropagation();_vm.filters.min_stock = false;
                _vm.reload();}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_c('v-btn',{staticClass:"internal",attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.clearMinStock($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$delete")])],1)],1)],1):_vm._e(),_c('v-spacer'),_c('v-col',{staticClass:"buttonInventory",staticStyle:{"margin-top":"-20px"},attrs:{"cols":"6","md":"3","align":"right"}},[_c('div',{staticStyle:{"width":"160px"}},[_c('FormMove',{attrs:{"title":_vm.tab},on:{"update":function($event){return _vm.reload()}}})],1)])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pa-0 mt-4",attrs:{"cols":"6","md":"3","align":"right"}},[_c('div',{staticStyle:{"width":"160px"}},[_c('FormMove',{attrs:{"title":_vm.tab},on:{"update":function($event){return _vm.reload()}}})],1)])],1):_vm._e(),_c('v-tabs-items',{class:_vm.$vuetify.breakpoint.xsOnly ? '' : 'mt-4',staticStyle:{"border-color":"red","border-width":"medium"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticStyle:{"width":"100%"},attrs:{"value":"general"}},[_c('base-data-table-cards',{attrs:{"footer-props":{
            'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
              ? ''
              : _vm.$t('items_per_page'),
          },"headers":_vm.headersGeneral,"headers_small":_vm.headersGeneral_small,"id":"tableGeneral","data":_vm.items,"totalItems":_vm.totalInventory},on:{"update":_vm.fetchAll},scopedSlots:_vm._u([{key:"item_invoice_number",fn:function(item){return [_c('UploadPayroll',{attrs:{"movement":item},on:{"generate":function($event){return _vm.$refs.invoice.generate(item)}}})]}},{key:"item_description",fn:function(item){return [(item.description)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({domProps:{"textContent":_vm._s(item.description)}},on))]}}],null,true)},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e()]}},{key:"item_data",fn:function(item){return [_c('span',[_vm._v(_vm._s(_vm.$d(new Date(item.data), "dateShort")))])]}},{key:"item_total",fn:function(item){return [_vm._v(" "+_vm._s(item.total ? _vm.$n(item.total, "currency") : "-")+" ")]}},{key:"item_stock",fn:function(item){return [_c('span',{class:[{ 'error--text': item.low_stock }]},[_vm._v(_vm._s(item.stock))])]}},{key:"item_receipts_issues",fn:function(item){return [_c('div',{staticClass:"success--text"},[_vm._v(_vm._s(item.receipts))]),_c('div',{staticClass:"error--text"},[_vm._v(_vm._s(item.issues))])]}}])})],1),_c('v-tab-item',{staticStyle:{"width":"100%"},attrs:{"value":"entradas"}},[_c('base-data-table-cards',{attrs:{"headers":_vm.headers,"headers_small":_vm.headers_small,"data":_vm.itemsReceipts,"total-items":_vm.totalReceipts},on:{"update":_vm.fetchAllReceipts},scopedSlots:_vm._u([{key:"item_total",fn:function(item){return [_vm._v(" "+_vm._s(item.total ? _vm.$n(item.total, "currency") : "-")+" ")]}},{key:"item_description",fn:function(item){return [(item.inventory.description)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({domProps:{"textContent":_vm._s(item.inventory.description)}},on))]}}],null,true)},[_vm._v(" "+_vm._s(item.inventory.description)+" ")]):_vm._e()]}},{key:"item_invoice_number",fn:function(item){return [_c('UploadPayroll',{attrs:{"movement":item},on:{"generate":function($event){return _vm.$refs.invoice.generate(item)}}})]}},{key:"item_data",fn:function(item){return [_c('span',[_vm._v(_vm._s(_vm.$d(new Date(item.data), "dateShort")))])]}},{key:"item_actions",fn:function(item){return [_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$edit")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.del(item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$delete")])],1)]}}])})],1),_c('v-tab-item',{attrs:{"value":"salidas"}},[_c('base-data-table-cards',{staticClass:"tables",attrs:{"headers":_vm.headers,"headers_small":_vm.headers_small,"data":_vm.itemsIssues,"options":_vm.options_issues,"totalItems":_vm.totalIssues},on:{"update:options":function($event){_vm.options_issues=$event},"update":_vm.fetchAllIssues},scopedSlots:_vm._u([{key:"item_total",fn:function(item){return [_vm._v(" "+_vm._s(item.total ? _vm.$n(item.total, "currency") : "-")+" ")]}},{key:"item_description",fn:function(item){return [(item.inventory.description)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({domProps:{"textContent":_vm._s(item.inventory.description)}},on))]}}],null,true)},[_vm._v(" "+_vm._s(item.inventory.description)+" ")]):_vm._e()]}},{key:"item_invoice_number",fn:function(item){return [_c('UploadPayroll',{attrs:{"movement":item},on:{"generate":function($event){return _vm.$refs.invoice.generate(item)}}})]}},{key:"item_data",fn:function(item){return [_c('span',[_vm._v(_vm._s(_vm.$d(new Date(item.data), "dateShort")))])]}},{key:"item_actions",fn:function(item){return [_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$edit")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.del(item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$delete")])],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("edit", { name: _vm.$tc("movement") })))]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('inventorymovements-form',{ref:"form",attrs:{"inventorymovement":_vm.inventorymovements,"action":_vm.action,"title":"view","productId":_vm.$route.params.id},on:{"cancel":function($event){_vm.dialog = false},"refreshTable":function($event){_vm.dialog = false;
            _vm.reload();}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }