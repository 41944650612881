<template>
  <div>
    <v-select
      class="my-4"
      v-if="$vuetify.breakpoint.xsOnly"
      :items="tabs"
      item-text="text"
      item-value="href"
      v-model="tab"
      outlined
      dense
      style="height: 20px"
    ></v-select>
    <!--
      <v-card>
        <v-tabs v-model="tabs" dark>
          <v-tab v-for="item in tabs" :key="item.href">
            {{ item.text }}
          </v-tab>
        </v-tabs>
      </v-card>
-->
    <v-tabs
      v-if="!$vuetify.breakpoint.xsOnly"
      v-model="tab"
      right
      class="pl-0"
      style="border-color: white; border-bottom: -2px; border-width: 1px"
    >
      <v-tab
        href="#general"
        class="pl-0 ml-0 pr-0"
        active-class="color"
        style="font-size: 14px"
        >{{ $t("inventorys.inventory") }}</v-tab
      >
      <v-tab
        href="#entradas"
        active-class="color"
        style="font-size: 14px"
        @click="type = null"
        >{{ $t("inventorys.receipts_table") }}</v-tab
      >

      <v-tab
        href="#salidas"
        active-class="color"
        style="font-size: 14px"
        @click="type = null"
        >{{ $t("inventorys.issues_table") }}</v-tab
      >
      <v-row justify="end">
        <v-col cols="4" class="py-0" v-if="tab !== 'general'">
          <v-select
            outlined
            dense
            class="mb-1"
            :label="
              $t(
                tab == 'entradas'
                  ? 'inventorys.type_product'
                  : 'inventorys.type_use'
              )
            "
            hide-details
            :items="types"
            v-model="type"
            @change="reload"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="5" v-if="count > 0">
          <v-btn
            color="red"
            :outlined="filters.min_stock !== true"
            elevation="0"
            height="30px"
            @click="
              filters.min_stock = true;
              reload();
            "
            block
          >
            {{ $t("inventorys.low_stock", { count }) }}
            <v-btn
              v-if="filters.min_stock"
              class="internal"
              icon
              color="black"
              @click.stop="
                filters.min_stock = false;
                reload();
              "
              ><v-icon>mdi-close</v-icon></v-btn
            >

            <v-btn
              v-else
              class="internal"
              icon
              color="red"
              @click.stop="clearMinStock"
              ><v-icon small>$delete</v-icon>
            </v-btn>
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col
          cols="6"
          md="3"
          align="right"
          style="margin-top: -20px"
          class="buttonInventory"
        >
          <div style="width: 160px">
            <FormMove :title="tab" @update="reload()"></FormMove>
          </div>
        </v-col>
      </v-row>
    </v-tabs>
    <v-row v-if="$vuetify.breakpoint.xsOnly" justify="end">
      <v-col cols="6" md="3" align="right" class="pa-0 mt-4">
        <div style="width: 160px">
          <FormMove :title="tab" @update="reload()"></FormMove>
        </div>
      </v-col>
    </v-row>
    <v-tabs-items
      :class="$vuetify.breakpoint.xsOnly ? '' : 'mt-4'"
      v-model="tab"
      style="border-color: red; border-width: medium"
    >
      <v-tab-item value="general" style="width: 100%">
        <base-data-table-cards
          :footer-props="{
            'items-per-page-text': $vuetify.breakpoint.smAndDown
              ? ''
              : $t('items_per_page'),
          }"
          :headers="headersGeneral"
          :headers_small="headersGeneral_small"
          id="tableGeneral"
          :data="items"
          :totalItems="totalInventory"
          @update="fetchAll"
        >
          <template v-slot:item_invoice_number="item">
            <UploadPayroll
              @generate="$refs.invoice.generate(item)"
              :movement="item"
            />
          </template>
          <template v-slot:item_description="item">
            <v-tooltip v-if="item.description" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on" v-text="item.description" />
              </template>
              {{ item.description }}
            </v-tooltip>
          </template>
          <template v-slot:item_data="item">
            <span>{{ $d(new Date(item.data), "dateShort") }}</span>
          </template>
          <template v-slot:item_total="item">
            {{ item.total ? $n(item.total, "currency") : "-" }}
          </template>
          <template v-slot:item_stock="item">
            <span v-bind:class="[{ 'error--text': item.low_stock }]">{{
              item.stock
            }}</span>
          </template>

          <template v-slot:item_receipts_issues="item">
            <div class="success--text">{{ item.receipts }}</div>
            <div class="error--text">{{ item.issues }}</div>
          </template>
        </base-data-table-cards>
      </v-tab-item>
      <v-tab-item value="entradas" style="width: 100%">
        <base-data-table-cards
          :headers="headers"
          :headers_small="headers_small"
          :data="itemsReceipts"
          :total-items="totalReceipts"
          @update="fetchAllReceipts"
        >
          <template v-slot:item_total="item">
            {{ $n(item.total, "currency") }}
          </template>
          <template v-slot:item_description="item">
            <v-tooltip v-if="item.inventory.description" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on" v-text="item.inventory.description" />
              </template>
              {{ item.inventory.description }}
            </v-tooltip>
          </template>
          <template v-slot:item_invoice_number="item">
            <UploadPayroll
              @generate="$refs.invoice.generate(item)"
              :movement="item"
            />
          </template>
          <template v-slot:item_data="item">
            <span>{{ $d(new Date(item.data), "dateShort") }}</span>
          </template>
          <template v-slot:item_total="item">
            {{ item.total ? $n(item.total, "currency") : "-" }}
          </template>
          <template v-slot:item_actions="item">
            <v-btn icon small class="mx-1" @click="edit(item)">
              <v-icon size="16">$edit</v-icon>
            </v-btn>
            <v-btn icon small class="mx-1" @click="del(item)">
              <v-icon size="16">$delete</v-icon>
            </v-btn>
          </template>
        </base-data-table-cards>
      </v-tab-item>
      <v-tab-item value="salidas">
        <base-data-table-cards
          class="tables"
          :headers="headers"
          :headers_small="headers_small"
          :data="itemsIssues"
          :options.sync="options_issues"
          :totalItems="totalIssues"
          @update="fetchAllIssues"
        >
          <template v-slot:item_total="item">
            {{ $n(item.total, "currency") }}
          </template>
          <template v-slot:item_description="item">
            <v-tooltip v-if="item.inventory.description" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on" v-text="item.inventory.description" />
              </template>
              {{ item.inventory.description }}
            </v-tooltip>
          </template>
          <template v-slot:item_invoice_number="item">
            <UploadPayroll
              @generate="$refs.invoice.generate(item)"
              :movement="item"
            />
          </template>
          <template v-slot:item_total="item">
            {{ item.total ? $n(item.total, "currency") : "-" }}
          </template>
          <template v-slot:item_data="item">
            <span>{{ $d(new Date(item.data), "dateShort") }}</span>
          </template>
          <template v-slot:item_actions="item">
            <v-btn icon small class="mx-1" @click="edit(item)">
              <v-icon size="16">$edit</v-icon>
            </v-btn>
            <v-btn icon small class="mx-1" @click="del(item)">
              <v-icon size="16">$delete</v-icon>
            </v-btn>
          </template>
        </base-data-table-cards>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-title>{{ $t("edit", { name: $tc("movement") }) }}</v-card-title>
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <inventorymovements-form
          ref="form"
          @cancel="dialog = false"
          :inventorymovement="inventorymovements"
          :action="action"
          title="view"
          :productId="$route.params.id"
          @refreshTable="
            dialog = false;
            reload();
          "
        />
      </v-card>
    </v-dialog>

    <!--
      <div>
        <InvoiceForm ref="invoice" />
      </div>
      -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { debounce } from "lodash";

export default {
  name: "MovementList",

  computed: {
    tabs() {
      return [
        { href: "general", text: this.$t("inventory") },
        { href: "entradas", text: this.$t("inventorys.receipts_table") },
        { href: "salidas", text: this.$t("inventorys.issues_table") },
      ];
    },
    types() {
      if (this.tab == "entradas")
        return [
          {
            text: this.$t("inventorys.merchandise"),
            value: "mercaderia",
          },
          {
            text: this.$t("inventorys.material_raw"),
            value: "mercaderia prima",
          },
        ];
      return [
        {
          text: this.$t("inventorys.sale"),
          value: "venta",
        },
        {
          text: this.$t("inventorys.consumption"),
          value: "consumo",
        },
      ];
    },
  },
  components: {
    FormMove: () => import("@/components/inventorymovement/FormMove"),
    InventorymovementsForm: () => import("@/components/inventorymovement/Form"),
    UploadPayroll: () => import("@/components/inventorymovement/UploadPayroll"),
    InvoiceForm: () => import("@/components/invoices/NewInvoiceInventory"),
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("inventorys.invoice_number"),
          align: "center",
          sortable: false,
          value: "invoice_number",
        },
        {
          text: this.$t("inventorymovement.data"),
          align: "center",
          sortable: false,
          value: "data",
        },
        {
          text: this.$t("inventorys.product_cody"),
          align: "center",
          sortable: false,
          value: "inventory.product_cody",
        },
        {
          text: this.$t("inventorys.description"),
          align: "center",
          sortable: false,
          value: "description",
        },

        {
          text: this.$t("product.quantity"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
        {
          text: this.$t("tattooers.price"),
          align: "center",
          sortable: false,
          value: "total",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      headersGeneral: [
        {
          text: this.$t("inventorys.product_cody"),
          align: "center",
          sortable: false,
          value: "product_cody",
        },
        {
          text: this.$t("inventorys.description"),
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("inventorys.cantidad_initial"),
          align: "center",
          sortable: false,
          value: "quantity",
        },
        {
          text: this.$t("inventorys.receipts"),
          align: "center",
          sortable: false,
          value: "receipts",
        },
        {
          text: this.$t("inventorys.issues"),
          align: "center",
          sortable: false,
          value: "issues",
        },

        {
          text: this.$t("inventorys.total"),
          align: "center",
          sortable: false,
          value: "stock",
        },
        {
          text: this.$t("inventorys.price_total"),
          align: "center",
          sortable: false,
          value: "total",
        },
      ],
      headers_small: [
        {
          text: this.$t("inventorymovement.data"),
          align: "center",
          sortable: false,
          value: "data",
        },
        {
          text: this.$t("inventorys.product_cody"),
          align: "center",
          sortable: false,
          value: "description",
        },

        {
          text: this.$t("product.quantity"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
      ],
      headersGeneral_small: [
        {
          text: this.$t("inventorys.product_cody"),
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("inventorys.cantidad_initial"),
          align: "center",
          sortable: false,
          value: "quantity",
          class: "text-center",
        },
        {
          text: this.$t("inventorys.receipts"),
          align: "center",
          sortable: false,
          value: "receipts_issues",
          class: "text-center py-0",
        },

        {
          text: this.$t("inventorys.total"),
          align: "center",
          sortable: false,
          value: "stock",
          class: "text-center primary--text",
        },
      ],
      tab: "general",
      items: null,
      itemsIssues: null,
      itemsReceipts: null,
      type: null,
      filters: {
        search: "",
        dates: "",
      },
      sortBy: "Nombre",
      rowsPerPageItems: [10, 25, 50, 100, 200],
      options: {},
      dialog: false,
      inventorymovements: {},
      options_issues: {},
      options_receipts: {},
      loading: true,
      totalInventory: null,
      totalReceipts: null,
      totalIssues: null,
      count: 0,
    };
  },
  mounted() {
    this.reload();
  },
  watch: {
    options_issues: {
      handler() {
        this.fetchAllIssues();
      },
      deep: true,
    },
    options: {
      handler() {
        this.fetchAll();
      },
      deep: true,
    },
    options_receipts: {
      handler() {
        this.fetchAllReceipts();
      },
      deep: true,
    },
  },
  methods: {
    clearMinStock() {
      this.$confirm(this.$t("clear_min_stock")).then(() => {
        this.reduceMinStock().then(() => {
          this.reload();
        });
      });
    },
    reload() {
      this.fetchAll();
      this.fetchAllIssues();
      this.fetchAllReceipts();
      this.fetchInventorysCount();
    },
    ...mapActions("inventory", [
      "getAll",
      "getInventorys",
      "getInventorysCounts",
      "reduceMinStock",
    ]),
    ...mapActions("inventorymovements", [
      "getInventorymovements",
      "delInventorymovements",
    ]),

    fetchInventorysCount() {
      this.getInventorysCounts({
        pagination: this.options,
        filters: { ...this.filters, min_stock: true },
      }).then((inventory) => {
        //console.log(inventory);
        this.count = inventory;
      });
    },
    fetchAll() {
      this.getInventorys({
        pagination: this.options,
        filters: this.filters,
      }).then((data) => {
        //console.log(data);
        this.items = data.data;
        this.totalInventory = data.total;
      });
    },
    fetchAllIssues() {
      this.getInventorymovements({
        pagination: this.options_issues,
        filters: { type: "issues", use: this.type },
      }).then((data) => {
        //console.log(data);
        this.itemsIssues = data.data;
        this.totalIssues = data.total;
      });
    },
    fetchAllReceipts() {
      this.getInventorymovements({
        pagination: this.options_receipts,
        filters: { type: "receipts", use: this.type },
      }).then((data) => {
        //console.log(data);
        this.itemsReceipts = data.data;
        this.totalReceipts = data.total;
      });
    },
    edit(inventorymovements) {
      this.action = "edit";
      //console.log(inventorymovements);
      this.inventorymovements = inventorymovements;

      this.dialog = true;
      setTimeout(() => {
        this.$refs.form.type_product = inventorymovements.cantidad < 0 ? 1 : 0;
      }, 500);
    },
    del(inventorymovements) {
      this.$confirm(
        this.$t("delete_inventorymovements_info", {
          type: this.title,
        }),
        "",
        ""
      ).then(() => {
        this.delInventorymovements(inventorymovements.id).then(() => {
          this.$alert(
            this.$t("delete_inventorymovements_confirm", {
              type: this.title,
            }),
            "",
            ""
          );
          this.reload();
        });
      });
    },
  },
};
</script>
<style lang="sass">
.filters .svg-icon, .filters .v-icon
  fill: var(--v-primary-base)
  color: var(--v-primary-base)
  height: 20px

.filters .v-label
  color: white
.v-application .col-6
  //padding-right: 12px !important
#tableGeneral
  th:nth-child(2)
    min-width: 120px
  td:nth-child(2)
    min-width: 120px
    max-width: 120px
.invList
  height: 30px
  .v-list-item__icon
    margin-right: 5px !important

.invList .v-icon, .invList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)

@media (min-width: 750px)
  #tableGeneral
    th:nth-child(1)
      min-width: 100px
    td:nth-child(1)
      max-width: 100px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      min-width: 100px
    th:nth-child(7)
      min-width: 120px
    td:nth-child(7)
      max-width: 120px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      min-width: 120px
    th:nth-child(6)
      min-width: 90px
    td:nth-child(6)
      max-width: 90px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      min-width: 90px
    th:nth-child(3)
      min-width: 90px
    td:nth-child(3)
      max-width: 90px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      min-width: 90px
    th:nth-child(5)
      min-width: 90px
    td:nth-child(5)
      max-width: 90px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      min-width: 90px
    th:nth-child(4)
      min-width: 90px
    td:nth-child(4)
      max-width: 90px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      min-width: 90px

    th:nth-child(2)
      min-width: 120px
    td:nth-child(2)
      min-width: 120px
      max-width: 120px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  .tables
    th:nth-child(1)
      min-width: 120px
    td:nth-child(1)
      max-width: 120px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      min-width: 120px
      max-width: 120px
    th:nth-child(2)
      min-width: 100px
    td:nth-child(2)
      min-width: 100px
      max-width: 100px
    td:nth-child(3)
      min-width: 90px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      max-width: 90px
    td:nth-child(4)
      min-width: 120px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      max-width: 120px
    td:nth-child(5)
      min-width: 90px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      max-width: 90px
    td:nth-child(6)
      min-width: 100px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      max-width: 100px
    td:nth-child(7)
      min-width: 90px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      max-width: 90px
  .shorter
    max-width: 170px
</style>
